<template>
  <b-card no-body>
    <b-card-header>
      <div>
        <b-card-title>
          <slot name="title">All Logins</slot>
        </b-card-title>
      </div>
    </b-card-header>

    <!-- chart -->
    <b-card-body v-if="!loading">
      <chartjs-component-bar-chart v-if="chartDataReady" v-show="!noData"
                                   ref="topChart"
                                   :height="300"
                                   :data="chartData"
                                   :options="chartjsData.latestBarChart.options"
      />

      <b-alert v-show="noData" variant="primary" show>
        <div class="alert-body">
          <span>No activities found for this period</span>
        </div>
      </b-alert>

    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BCardTitle,
} from 'bootstrap-vue'

import helperService from '@/services/HelperService';
import OwnersService from '@/services/OwnersService';
import { $themeColors } from '@themeConfig';
import chartjsData from './chartjsData'
import ChartjsComponentLineChart from "@/components/charts/charts-components/ChartjsComponentLineChart.vue";
import ChartjsComponentBarChart from "@/components/charts/charts-components/ChartjsComponentBarChart.vue";

export default {
  components: {
    ChartjsComponentBarChart,
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
  },
  props: {
    uid: {
      required: false,
      type: String,
      default: '',
    },
    from: {
      required: false,
      type: String,
      default: '',
    },
    to: {
      required: false,
      type: String,
      default: '',
    },
    groupUid: {
      required: false,
      type: String,
      default: '',
    },
    unique: {
      required: false,
      type: String,
      default: '',
    }
  },
  data() {
    return {
      chartDataReady: false,
      loading: true,
      chartjsData,
      noData: false,
      filters: {
        page: 0,
        page_size: 10,
        search: '',
        app: '',
        from: new Date(new Date().setDate(new Date().getDate() -  7)).toDateString(),
        to: new Date().toDateString(),
        owner_uid: '',
        group_uid: '',
        unique: '',
      },
      chartData: {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: $themeColors.primary,
            borderColor: 'transparent',
          },
        ],
      },
    }
  },
  watch: {
    from: function() {
      this.filters.from = this.from;
      this.filters.to = this.to;
      this.getLogins()
    },
    to: function() {
      this.filters.from = this.from;
      this.filters.to = this.to;
      this.getLogins()
    },
  },
  mounted() {
    if (this.uid !== '') {
      this.filters.owner_uid = this.uid
    }

    if (this.groupUid !== '') {
      this.filters.group_uid = this.groupUid
    }

    if (this.from !== null) {
      this.filters.from = this.from;
    }

    if (this.to !== null) {
      this.filters.to = this.to;
    }

    if (this.unique !== '') {
      this.filters.unique = this.unique;
    }

    this.getLogins();
  },
  methods: {
    async getLogins() {
      this.loading = true
      OwnersService.getLogins(this.filters).then(res => {
        // CLear the arrays
        this.chartData.labels = [];
        this.chartData.datasets[0].data = [];
        let noValues = true
        res.data.data.forEach(item => {
          this.chartData.labels.push(item.date)
          this.chartData.datasets[0].data.push(item.count)
          if (item.count > 0) {
            noValues = false
          }
        })

        this.chartDataReady = true
        this.noData = res.data.data.length === 0 || noValues
      }).catch(err => {
        helperService.showNotfyErr(this.$toast, err, 'Could not get activities, please refresh and try again')
      })
      .finally(() => {
        this.loading = false
      });
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
